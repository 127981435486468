import {
  GET_TICKETS,
  DELETE_TICKET,
  UPDATE_TICKET_STATUS,
} from '../actions/ticketActions';

const initialState = {
  tickets: [],
};

export default function ticketReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS: {
      return {
        ...state,
        tickets: [...action.payload],
      };
    }
    case DELETE_TICKET: {
      const tickets = [...state.tickets];
      const updatedTicktes = tickets.filter((ticket) => {
        return ticket._id !== action.payload._id;
      });
      return {
        ...state,
        tickets: updatedTicktes,
      };
    }
    case UPDATE_TICKET_STATUS: {
      const tickets = [...state.tickets];
      const updatedTicktes = tickets.map((ticket) => {
        if (ticket._id === action.payload._id) {
          return {
            ...ticket,
            status: action.payload.status,
          };
        }
        return ticket;
      });
      return {
        ...state,
        tickets: updatedTicktes,
      };
    }
    // case RESOLVE_TICKET: {
    //   const tickets = [...state.tickets];
    //   const updatedTicktes = tickets.map((ticket) => {
    //     if (ticket._id === action.payload._id) {
    //       return {
    //         ...ticket,
    //         status: 'resolved',
    //       };
    //     }
    //     return ticket;
    //   });
    //   return {
    //     ...state,
    //     tickets: updatedTicktes,
    //   };
    // }
    // case CLOSE_TICKET: {
    //   const tickets = [...state.tickets];
    //   const updatedTicktes = tickets.map((ticket) => {
    //     if (ticket._id === action.payload._id) {
    //       return {
    //         ...ticket,
    //         status: 'closed',
    //       };
    //     }
    //     return ticket;
    //   });
    //   return {
    //     ...state,
    //     tickets: updatedTicktes,
    //   };
    // }

    default:
      return state;
  }
}
