export const ADMIN_GET_PROMOTIONS = 'ADMIN_GET_PROMOTIONS';
export const ADMIN_ADD_PROMOTION = 'ADMIN_ADD_PROMOTION';
export const ADMIN_UPDATE_PROMOTION = 'ADMIN_UPDATE_PROMOTION';
export const ADMIN_DELETE_PROMOTION = 'ADMIN_DELETE_PROMOTION';

export function adminGetPromotions(payload) {
  return { type: ADMIN_GET_PROMOTIONS, payload };
}
export function adminAddPromotion(payload) {
  return { type: ADMIN_ADD_PROMOTION, payload };
}
export function adminUpdatePromotion(payload) {
  return { type: ADMIN_UPDATE_PROMOTION, payload };
}
export function adminDeletePromotion(payload) {
  return { type: ADMIN_DELETE_PROMOTION, payload };
}
