import {
  ADMIN_GET_PROMOTIONS,
  ADMIN_ADD_PROMOTION,
  ADMIN_UPDATE_PROMOTION,
  ADMIN_DELETE_PROMOTION,
} from '../actions/promotionActions';

const initialState = {
  promotions: [],
};

export default function promotionReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_PROMOTIONS: {
      return {
        ...state,
        promotions: [...action.payload],
      };
    }
    case ADMIN_ADD_PROMOTION: {
      const promotions = [...state.promotions];
      promotions.push(action.payload);
      return {
        ...state,
        promotions: promotions,
      };
    }
    case ADMIN_UPDATE_PROMOTION: {
      const promotions = [...state.promotions];
      let index = promotions.findIndex(
        (item) => item._id === action.payload._id,
      );
      promotions[index] = action.payload;
      return {
        ...state,
        promotions: promotions,
      };
    }
    case ADMIN_DELETE_PROMOTION: {
      const promotions = [...state.promotions];
      let index = promotions.findIndex(
        (item) => item._id === action.payload._id,
      );
      promotions.splice(index, 1);
      return {
        ...state,
        promotions: promotions,
      };
    }
    default:
      return state;
  }
}
