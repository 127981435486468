export const GET_TICKETS = 'GET_TICKETS';
export const DELETE_TICKET = 'DELETE_TICKET';
export const UPDATE_TICKET_STATUS = 'UPDATE_TICKET_STATUS';
// export const RESOLVE_TICKET = 'RESOLVE_TICKET';
// export const CLOSE_TICKET = 'CLOSE_TICKET';

export function getTickets(payload) {
  return { type: GET_TICKETS, payload };
}

export function deleteTicket(payload) {
  return {
    type: DELETE_TICKET,
    payload,
  };
}

export function updateTicketStatus(payload) {
  return {
    type: UPDATE_TICKET_STATUS,
    payload,
  };
}

// export function resolveTicket(payload) {
//   return {
//     type: RESOLVE_TICKET,
//     payload,
//   };
// }

// export function closeTicket(payload) {
//   return {
//     type: CLOSE_TICKET,
//     payload,
//   };
// }
