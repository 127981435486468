export const ADMIN_GET_OFFERS = 'ADMIN_GET_OFFERS';
export const ADMIN_ADD_OFFER = 'ADMIN_ADD_OFFER';
export const ADMIN_UPDATE_OFFER = 'ADMIN_UPDATE_OFFER';
export const ADMIN_DELETE_OFFER = 'ADMIN_DELETE_OFFER';

export function adminGetOffers(payload) {
  return { type: ADMIN_GET_OFFERS, payload };
}
export function adminAddOffer(payload) {
  return { type: ADMIN_ADD_OFFER, payload };
}
export function adminUpdateOffer(payload) {
  return { type: ADMIN_UPDATE_OFFER, payload };
}
export function adminDeleteOffer(payload) {
  return { type: ADMIN_DELETE_OFFER, payload };
}
