import {
  ADMIN_GET_KYCS,
  ADMIN_APPROVE_KYC,
  ADMIN_REJECT_KYC,
  ADMIN_DELETE_KYC,
} from '../actions/kycActions';

const initalState = {
  kycs: [],
};
export default function kycReducer(state = initalState, action) {
  switch (action.type) {
    case ADMIN_GET_KYCS: {
      return {
        ...state,
        kycs: [...action.payload],
      };
    }
    case ADMIN_DELETE_KYC: {
      const kycs = [...state.kycs];
      const index = kycs.filter(({ _id }) => _id !== action.payload._id);
      kycs.splice(index, 1);
      return {
        ...state,
        kycs,
      };
    }
    case ADMIN_APPROVE_KYC: {
      const kycs = [...state.kycs];
      const updatedKycs = kycs.map((kyc) => {
        if (kyc._id === action.payload._id) {
          return {
            ...kyc,
            verified: true,
          };
        }
        return kyc;
      });
      return {
        ...state,
        kycs: updatedKycs,
      };
    }
    case ADMIN_REJECT_KYC: {
      const kycs = [...state.kycs];
      const updatedKycs = kycs.map((kyc) => {
        if (kyc._id === action.payload._id) {
          return {
            ...kyc,
            verified: false,
          };
        }
        return kyc;
      });
      return {
        ...state,
        kycs: updatedKycs,
      };
    }
    default:
      return state;
  }
}
