import {
  ADMIN_GET_SERVICES,
  ADMIN_ADD_SERVICE,
  ADMIN_UPDATE_SERVICE,
  ADMIN_DELETE_SERVICE,
} from '../actions/serviceActions';

const initialState = {
  services: [],
};

export default function serviceReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_SERVICES: {
      return {
        ...state,
        services: [...action.payload],
      };
    }
    case ADMIN_ADD_SERVICE: {
      const services = [...state.services];
      services.push(action.payload);
      return {
        ...state,
        services: services,
      };
    }
    case ADMIN_UPDATE_SERVICE: {
      const services = [...state.services];
      let index = services.findIndex((item) => item._id === action.payload._id);
      services[index] = action.payload;
      return {
        ...state,
        services: services,
      };
    }
    case ADMIN_DELETE_SERVICE: {
      const services = [...state.services];
      let index = services.findIndex((item) => item._id === action.payload._id);
      services.splice(index, 1);
      return {
        ...state,
        services: services,
      };
    }
    default:
      return state;
  }
}
