import {
  ADMIN_GET_OFFERS,
  ADMIN_ADD_OFFER,
  ADMIN_UPDATE_OFFER,
  ADMIN_DELETE_OFFER,
} from '../actions/offerActions';

const initialState = {
  offers: [],
};

export default function offerReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_OFFERS: {
      return {
        ...state,
        offers: [...action.payload],
      };
    }
    case ADMIN_ADD_OFFER: {
      const offers = [...state.offers];
      offers.push(action.payload);
      return {
        ...state,
        offers: offers,
      };
    }
    case ADMIN_UPDATE_OFFER: {
      const offers = [...state.offers];
      let index = offers.findIndex((item) => item._id === action.payload._id);
      offers[index] = action.payload;
      return {
        ...state,
        offers: offers,
      };
    }
    case ADMIN_DELETE_OFFER: {
      const offers = [...state.offers];
      let index = offers.findIndex((item) => item._id === action.payload._id);
      offers.splice(index, 1);
      return {
        ...state,
        offers: offers,
      };
    }
    default:
      return state;
  }
}
