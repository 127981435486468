export const ADMIN_GET_KYCS = 'ADMIN_GET_KYCS';
export const ADMIN_APPROVE_KYC = 'ADMIN_APPROVE_KYC';
export const ADMIN_DELETE_KYC = 'ADMIN_DELETE_KYC';
export const ADMIN_REJECT_KYC = 'ADMIN_REJECT_KYC';

export function adminGetKycs(payload) {
  return { type: ADMIN_GET_KYCS, payload };
}

export function adminAproveKyc(payload) {
  return {
    type: ADMIN_APPROVE_KYC,
    payload,
  };
}

export function adminDeletKyc(payload) {
  return {
    type: adminDeletKyc,
    payload,
  };
}

export function adminRejectKyc(payload) {
  return {
    type: ADMIN_REJECT_KYC,
    payload,
  };
}
