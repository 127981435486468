export const ADMIN_GET_SERVICES = 'ADMIN_GET_SERVICES';
export const ADMIN_ADD_SERVICE = 'ADMIN_ADD_SERVICE';
export const ADMIN_UPDATE_SERVICE = 'ADMIN_UPDATE_SERVICE';
export const ADMIN_DELETE_SERVICE = 'ADMIN_DELETE_SERVICE';

export function adminGetServices(payload) {
  return { type: ADMIN_GET_SERVICES, payload };
}
export function adminAddService(payload) {
  return { type: ADMIN_ADD_SERVICE, payload };
}
export function adminUpdateService(payload) {
  return { type: ADMIN_UPDATE_SERVICE, payload };
}
export function adminDeleteService(payload) {
  return { type: ADMIN_DELETE_SERVICE, payload };
}
