import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// import DashboardLayout from 'src/layouts/DashboardLayout';
// import MainLayout from 'src/layouts/MainLayout';
// import AccountView from 'src/views/account/AccountView';
// import UserListView from 'src/views/user/UserListView';
// import DashboardView from 'src/views/reports/DashboardView';
// import LoginView from 'src/views/auth/LoginView';
// import NotFoundView from 'src/views/errors/NotFoundView';
// import SettingsView from 'src/views/settings/SettingsView';
// import Error500 from './views/errors/Error500';
// import TransactionView from './views/transaction/TransactionView';
// import OfferView from './views/offer/OfferView';
// import PromotionView from './views/promotion/PromotionView';
// import ServiceView from './views/service/ServiceView';

// Lazy load all above views

const MainLayout = lazy(() => import('src/layouts/MainLayout'));
const DashboardLayout = lazy(() => import('src/layouts/DashboardLayout'));

const DashboardView = lazy(() => import('src/views/reports/DashboardView'));
const LoginView = lazy(() => import('src/views/auth/LoginView'));
const NotFoundView = lazy(() => import('src/views/errors/NotFoundView'));
const AccountView = lazy(() => import('src/views/account/AccountView'));
const SettingsView = lazy(() => import('src/views/settings/SettingsView'));
const UserListView = lazy(() => import('src/views/user/UserListView'));
const TransactionView = lazy(() =>
  import('src/views/transaction/TransactionView'),
);
const OfferView = lazy(() => import('src/views/offer/OfferView'));
const PromotionView = lazy(() => import('src/views/promotion/PromotionView'));
const ServiceView = lazy(() => import('src/views/service/ServiceView'));
const Error500 = lazy(() => import('src/views/errors/Error500'));
const TermsAndConditionsView = lazy(() =>
  import('src/views/termsAndConditions/termsAndConditionsView'),
);

const PrivacyPolicyView = lazy(() =>
  import('src/views/termsAndConditions/privacyPolicyView'),
);
const HelpView = lazy(() => import('src/views/termsAndConditions/helpView'));

const TicketView = lazy(() => import('src/views/ticket/ticketView'));

const KycView = lazy(() => import('src/views/kyc/kycView'));

import { isLoggedIn } from './utils/helper';

export const getRoutes = (pathname) => {
  const routes = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: isLoggedIn() ? <Navigate to="/" /> : <LoginView />,
        },
        { path: '404', element: <NotFoundView /> },
        { path: '500', element: <Error500 /> },
        {
          path: '/',
          element: isLoggedIn() ? (
            <DashboardLayout />
          ) : (
            <Navigate to="/login" state={{ pathname }} />
          ),
          children: [
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: 'account', element: <AccountView /> },
            { path: '/users/:slug', element: <UserListView /> },
            { path: 'dashboard', element: <DashboardView /> },
            { path: 'settings', element: <SettingsView /> },
            { path: '*', element: <NotFoundView /> },
            {
              path: 'transaction',
              children: [
                {
                  path: '/',
                  element: <TransactionView />,
                },
              ],
            },
            {
              path: 'offer',
              children: [
                {
                  path: '/',
                  element: <OfferView />,
                },
              ],
            },
            {
              path: 'promotion',
              children: [
                {
                  path: '/',
                  element: <PromotionView />,
                },
              ],
            },
            {
              path: 'service',
              children: [
                {
                  path: '/',
                  element: <ServiceView />,
                },
              ],
            },
            {
              path: 'policies',
              children: [
                {
                  path: '/terms-and-conditions',
                  element: <TermsAndConditionsView />,
                },
                {
                  path: '/privacy-policy',
                  element: <PrivacyPolicyView />,
                },
                {
                  path: '/help',
                  element: <HelpView />,
                },
              ],
            },
            {
              path: 'ticket',
              children: [
                {
                  path: '/',
                  element: <TicketView />,
                },
              ],
            },
            {
              path: 'kyc',
              children: [
                {
                  path: '/',
                  element: <KycView />,
                },
              ],
            },
          ],
        },
        { path: '*', element: <NotFoundView /> },
      ],
    },
    // {
    //   path: '/',
    //   element: isLoggedIn() ? <DashboardLayout /> : <Navigate to="/login" />,
    //   children: [
    //     { path: 'account', element: <AccountView /> },
    //     { path: 'customers', element: <UserListView /> },
    //     { path: 'dashboard', element: <DashboardView /> },
    //     { path: 'products', element: <ProductListView /> },
    //     { path: 'settings', element: <SettingsView /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
  ];
  return routes;
};

// export default routes;
