import {
  ADMIN_ADD_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_USERS,
  ADMIN_UPDATE_USER,
  ADMIN_TRANSACTION_ALL,
} from '../actions/adminActions';

const initialState = {
  users: [],
  pages: [],
  blogs: [],
  bookings: [],
  ledgers: [],
  families: [],
  menuitems: [],
  menureviews: [],
  orders: [],
  newsletters: [],
  featureds: [],
  featuredmenus: [],
  deliveryMans: [],
  tranasctions: [],
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    // USERS
    case ADMIN_GET_USERS:
      return {
        ...state,
        users: [...action.payload],
      };

    case ADMIN_ADD_USER: {
      const users = [...state.users];
      users.push(action.payload);
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_UPDATE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload._id);
      users[index] = action.payload;
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_DELETE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload);
      users.splice(index, 1);
      return {
        ...state,
        users: users,
      };
    }
    // TRANSACTION
    case ADMIN_TRANSACTION_ALL:
      return {
        ...state,
        transactions: [...action.payload],
      };
    default:
      return state;
  }
}
