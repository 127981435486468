import { combineReducers } from 'redux';

import userReducer from './userReducer';
import adminReducer from './adminReducer';
import offerReducer from './offerReducer';
import promotionReducer from './promotionReducer';
import serviceReducer from './serviceReducer';
import termsAndConditionsReudcers from './termsAndConditionsReducer';
import privacyPolicyReudcers from './privacyPolicyReducer';
import helpReudcers from './helpReducer';
import kycReducer from './kycReducer';
import ticketReducer from './ticketReducer';

const appReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  offer: offerReducer,
  promotion: promotionReducer,
  service: serviceReducer,
  termsAndConditions: termsAndConditionsReudcers,
  privacyPolicy: privacyPolicyReudcers,
  help: helpReudcers,
  kyc: kycReducer,
  ticket: ticketReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
